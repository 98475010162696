<template>
  <div>
    <SigningLoanAgreement />
  </div>
</template>

<script>
import { mapState } from "vuex";

import SigningLoanAgreement from "@/components/dashboard/pages/signing-loan-agreement/SigningLoanAgreement";

export default {
  name: "LimeLoansSigningLoanAgreementPage",

  components: { SigningLoanAgreement },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      auth: "auth",
    }),
  },

  created() {
    if (!this.auth.user.open_application.can_sign_agreement) {
      this.$router.push({ name: "DashboardMyLoanPage" });
    } else if (this.auth.user.open_application.has_signed_agreement) {
      this.$router.push({ name: "DashboardDebitOrderApprovalPage" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
